<template>
  <header>
    <div class="content">
      <div class="logo">
        <router-link to="/">
          <span class="icon" /><span>RoyalCredit</span>
        </router-link>
      </div>
      <div class="menu">
        <div :class="['menu_item', {'menu_item_focus': $route.path === item.path}]" v-for="(item, index) in menu" :key="index">
          <router-link :to="item.path">{{ item.value }}</router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    menu() {
      return [
        { name: 'Home', value: this.$t('home'), path: '/' },
        { name: 'About', value: this.$t('about'), path: '/About' },
        // { name: 'payment', value: this.$t('payment'), path: '/payment' },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {
  @media screen and (-webkit-min-device-pixel-ratio: 1),
  screen and (min--moz-device-pixel-ratio: 1) {
    @import '../assets/scss/sprite/header.scss';
    .logo {
      .icon {
        @include sprite($logo);
      }
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2) {
    @import '../assets/scss/sprite/header@2x.scss';
    .logo {
      .icon {
        @include sprite($logo-2x);
      }
    }
  }
}

header {
  @include pixel-ratio();
  border-bottom: 1px solid #F6F6F6;
  .content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    height: 80px;
    line-height: 80px;
    .logo {
      display: inline-block;
      font-size: 20px;
      flex: 1;
      text-align: left;
      .icon {
        display: inline-block;
        margin-right: 13px;
        vertical-align: middle;
      }
      a {
        text-decoration: none;
        color: #433760;
      }
    }
    .menu {
      display: flex;
      .menu_item {
        text-align: center;
        flex: 1;
        padding: 0 10px;
        white-space: nowrap;
        a {
          text-decoration: none;
          color: #f5cb55;
          cursor: pointer;
          font-weight: 700;
          font-size: 18px;
          &:hover {
            color: #2433ac;
          }
        }
        &_focus a{
          color: #2433ac;
        }
      }
    }
  }
}
</style>
