export default {
  home: 'Trang chủ',
  payment: 'Phương thức thanh toán',
  footer: {
    userAgreement: 'Thoả thuận người dùng',
    securityPolicy: 'Chính sách bảo mật',
    listTips: [
      'Copyright © 2024 RoyalCredit. All Rights Reserved.',
    ],
    contactUs: 'Liên hệ chúng tôi',
    phone: '+234 9039162704',
    email: 'olay56862@gmail.com',
    facebookUrl: '',
    facebookName: 'RoyalCredit',
  },
  homePage: {
    loan: {
      title: 'Chỉ cần bạn muốn vay, bạn sẽ được vay ngay',
      list: [
        {
          text: 'Vui lòng điền vào đơn đăng ký',
          info: 'Chỉ mất <span>5 phút</span> điền thông tin',
        },
        {
          text: 'Thẩm định nhanh chóng',
          info: 'Thẩm định nhanh trong <span>2 giờ</span>',
        },
        {
          text: 'Nhận được khoản vay',
          info: 'Tiền đến tài khoản trong vòng <span>24h</span> đăng ký.',
        },
      ],
    },
    step: {
      title: 'Lợi ích thêm của RoyalCredit',
      list: [
        'Bạn chọn lựa "Hạn mức" và "Kỳ hạn"',
        'Bạn có thể đăng ký vay bất cứ khi nào bạn cần tiền.',
        'Bạn có thể điền đơn đăng ký vào bất kỳ ngày đêm, cuối tuần, lễ tết…để nhận được khoản vay. Chúng tôi sẽ chuyển cho bạn bất cứ khi nào.',
        'Bạn chỉ cần đăng ký và điền vào mẫu. Bạn chỉ cần kết nối internet. Toàn bộ quá trình được thực hiện trực tiếp, không cần phải chờ đợi!',
      ],
    },
  },
  paymentPage: {
    title: 'Tài khoản công ty',
    list: [
      {
        title: '1. Ngân hàng thương mại cổ phần Kỹ Thương Việt Nam (Techcombank).',
        list: [
          'Tên ngân hàng: Ngân hàng thương mại cổ phần Kỹ Thương Việt Nam',
          'Chi nhánh: Quận 7, PGD Phú Mỹ Hưng',
          'Số tài khoản: 19134897785010',
          'Tên công ty: CÔNG TY TNHH DỊCH VỤ TÀI CHÍNH WIN WIN',
          'Nội dung thanh toán: Số hợp đồng + Số CMND khách hàng',
        ],
      },
      {
        title: '2. Ngân hàng thương mại cổ phần Sài Gòn Thương Tín (Sacombank).',
        list: [
          'Tên ngân hàng: Ngân hàng thương mại cổ phần Sài Gòn Thương Tín',
          'Chi nhánh: Quận 7, PGD Nguyễn Lương Bằng',
          'Số tài khoản: 060232692408',
          'Tên công ty: CÔNG TY TNHH DỊCH VỤ TÀI CHÍNH WIN WIN',
          'Nội dung thanh toán: Số hợp đồng + Số CMND khách hàng',
        ],
      },
      {
        title: '3. Ngân hàng thương mại cổ phần Ngoại thương Việt Nam (Vietcombank).',
        list: [
          'Tên ngân hàng: Ngân hàng thương mại cổ phần Ngoại thương Việt Nam',
          'Chi nhánh: CN Nam Sài Gòn',
          'Số tài khoản: 0181003646354',
          'Tên công ty: CÔNG TY TNHH DỊCH VỤ TÀI CHÍNH WIN WIN',
          'Nội dung thanh toán: Số hợp đồng + Số CMND khách hàng',
        ],
      },
    ],
    info1: 'Thời gian làm việc của ngân hàng như sau:',
    info2: 'Từ thứ Hai đến thứ Sáu, 8:00-12:00; 13:30-17:00.',
  },
};
