<template>
  <div class="page">
    <div class="page_title">{{ $t('aboutPage.title') }}</div>
    <div class="content">
      <p>Papichou exists to solve the critical issues facing our financial clients, both large and small. We offer customized services and partnership to assess the creditworthiness of individuals.</p>
      <H3>VISION</H3>
      <p>We are a fintech provider of alternative lending solutions for Credit Scoring, Fraud Detection & Identity Check. It brings innovations into emerging markets by supplying mobile financial services to the underbanked. The company’s products use cutting-edge algorithms, machine-learning & data sciences to provide real-time Credit Scoring & Lending-As-A-Service (LaaS) solutions to expand client basis for diverse business targets.</p>
      <H3>MISSION</H3>
      <p>Our mission is to provide our customers with easy access to loans through an effective and efficient route.</p>
      <p>RoyalCredit is an online loan app registered under Papichou Technology Limited, customers can achieve an effective loan application process through the RoyalCredit app.</p>
      <p>Papichou Technology Limited seeks to meet the financial needs of our customers to help maintain financial stability.</p>
      <p>Download our app on playstore and follow the registration procedures, upload a valid ID card, I.e ( International passport, NIN or voters card issued by the Nigerian Government ), then fill in the application form to complete your registration. Within a few minutes, our loan decision will be communicated through the mobile application and the loan will be disbursed immediately to your preferred method of withdrawal.</p>
    </div>
    <div>
      <div class="info">
        <div class="title" >Contact us</div>
        <div class="text">
          <p>For more enquiries about using our services, please contact our customer service team:</p>
          <p>Email: olay56862@gmail.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'payment',
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {
  @media screen and (-webkit-min-device-pixel-ratio: 1),
  screen and (min--moz-device-pixel-ratio: 1) {
    @import '../assets/scss/sprite/payment.scss';
    .star {
      @include sprite($star);
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2) {
    @import '../assets/scss/sprite/payment@2x.scss';
    .star {
      @include sprite($star-2x);
    }
  }
}

.page {
  @include pixel-ratio();
  text-align:justify;
  text-justify:newspaper;
  width: 60%;
  margin: 0 auto;
  &_title {
    font-size: 30px;
    color: #252525;
  }
  .content {
    margin: 0 auto;
    display: inline-block;
    line-height: 24px;
    > p{
      margin: 10px 0;
    }
  }
  .info {
    display: inline-block;
    margin: 20px auto;
    .title {
      font-size: 30px;
      color: #252525;
    }
    .text {
      display: inline-block;
      color: #252525;
      font-size: 18px;
      > p{
        margin: 10px 0;
      }
    }
  }
}
</style>
